import '../Stylesheets/Main.css';

import NewsList from './NewsList';
import PageHeader from './Header/PageHeader';

import BackgroundNewsPage from './../images/background/news-page.png'

import { useState, useEffect } from 'react';

function NewsPage() {

  const [windowHeight, setHeight] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

  }, [windowHeight]);

    return(
      <div className='news-page'>
        <PageHeader></PageHeader>
        <div className='news-wrapper' style={{ height: windowHeight}}>
          <img src={BackgroundNewsPage} alt="" class="news-background" />
          <NewsList></NewsList>
        </div>
        
      </div>
      );
  }

export default NewsPage;