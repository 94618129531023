
import '../Stylesheets/Main.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


import { useRef, useState, useEffect, createContext } from 'react';
import DesktopMain from './DesktopMain';
import MobileMain from './MobileMain';

export const CookieContext = createContext({});
export const BannerContext = createContext({});

function MainPage() {

  const [consent, setConsent] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const [windowWidth, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);

  let mainPage;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if(window.innerWidth < 900){
        setMobile(true);
        mainPage = <MobileMain />
      }
      else{
        setMobile(false);
        mainPage = <DesktopMain />
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

  }, [windowWidth]);

    return(
      <CookieContext.Provider value={{ consent: consent, setConsent: setConsent }} >
      <BannerContext.Provider value={{ showBanner: showBanner, setShowBanner: setShowBanner }} >
      <div>
        {mobile
        ? <MobileMain />
        : <DesktopMain />
      }
      </div>
      </BannerContext.Provider>
      </CookieContext.Provider>
      );
  }

export default MainPage;