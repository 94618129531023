import "../../Stylesheets/Main.css"

import PageNavDesktop from "./PageNavDesktop.jsx"
import PageNavMobile from "./PageNavMobile.jsx"

import logoIcon from "../../images/logo-icon.png"
import logoTitle from "../../images/logo-title.png"

const PageHeader = () => {

    return (
    <div className = "header-wrapper news-header card-shadow">
        <div>
            <img src = {logoIcon} alt="Guidos Fahrradwelt Logo" className = "header-icon" />
        </div>

        <div className = "header-box">
            <img src= {logoTitle} alt="Guidos Fahrradwel" className = "header-title" />
            <PageNavDesktop></PageNavDesktop>
            <PageNavMobile></PageNavMobile>
        </div>
    </div>
    );
}

export default PageHeader