import "./../Stylesheets/Main.css"

const Address = () => {
    return (
        <div className="address-wrapper background-text">
            <div className="address-column">
                <p>
                Guido's Fahrradwelt <br />
                Hermannstraße 32 <br />
                12049 Berlin Neukölln <br />
                Tel.: (030) 622 327 5 <br />
                Fax: (030) 666 45 969
                </p>
            </div>
            <div className="address-column column-middle">
                <p>
                Öffnungszeiten <br />
                Dienstag - Freitag: 10:00 - 18:30 <br />
                Samstag: 10:00 - 14:00 <br />
                Sonntag und Montag geschlossen.
                </p>
            </div>
            <div className="address-column">
                <p>
                Guido's Fahrradwelt <br />
                Morgensternstraße 1 <br />
                12207 Berlin Lichterfelde <br />
                Tel: (030) 270 151 61 <br />
                Fax (030) 270 151 62
                </p>
            </div>
        </div>
    );
}

export default Address;