import "./../Stylesheets/Main.css"

const Address = () => {
    return (
        <div className="service-wrapper background-text">
            <div className="service-column service-title">
                <h1 className="desktop-heading">
                    Service
                </h1>
            </div>

            <div className="service-column">
                <h2>
                    Finanzierung
                </h2>
                <p>
                Ihr neues Fahrrad kann über folgende Anbieter zu günstigen Konditionen finanziert werden. <br />
                Unsere Partner:
                </p>
                <ul>
                    <li> Finance A Bike </li>
                    <li> Ratenkauf by EasyCredit </li>
                </ul>
            </div>
                
            <div className="service-column">
                <h2>
                    Versicherung
                </h2>
                <p>
                Wir versichern Ihr Fahrrad  und passen den Versicherungsschutz auf Ihre Bedürfnisse an. <br />
                Unsere Partner:
                </p>
                <ul>
                    <li> ENRA E-Versicherung </li>
                    <li> Schleswiger Versicherungsverein </li>
                    <li> Wertgarantie </li>
                </ul>
            </div>
        </div>
    );
}

export default Address;