import "./../Stylesheets/Main.css"

import NewsImage from "../images/news/batavus.png"


const EbikeCard = () => {
    return (
    <div className="ebike-card-wrapper ">
        <h1 className="mobile-heading section-title-big card-title-shadow title-right">
                Service
        </h1>
        <div className="ebike-card card-shadow">
            <div className="leasing-text-wrapper">
            <h2>
                Ebike-Service
            </h2>
            <p className="leasing-text">
                Wir sind zertfizierter Partner und Servicestelle folgender Ebike- und Pedelecmarken:
            </p>
            </div>
            <ul className="leasing-list">
                <li> Bosch </li>
                <li> Giant </li>
                <li> Yamaha </li>
                <li> Brose </li>
                <li> Shimano </li>
                <li> Panasonic </li>
                <li> Fit Ebike </li>
                <li> BMZ </li>
            </ul>
        </div>
    </div>
    );
}

export default EbikeCard;