import "./../Stylesheets/Main.css"

import NewsImage from "../images/news/batavus.png"


const LeasingCard = () => {
    return (
    <div className="leasing-card-wrapper ">
        <h1 className="mobile-heading section-title-big card-title-shadow title-right">
                    Service
        </h1>
        <div className="leasing-card card-shadow">
            <div className="leasing-text-wrapper">
            <h2>
                Leasing
            </h2>
            <p className="leasing-text">
                Wir bieten Ihnen Fahrradleasing zu günstigen Konditionen. Entdecken Sie jetzt unsere Leasingpartner.
            </p>
            </div>
            <ul className="leasing-list">
                <li> jobrad </li>
                <li> Deutsche Dienstrad </li>
                <li> mein-dienstrad.de </li>
                <li> bikeleasing </li>
                <li> companybike </li>
                <li> businessbike </li>
                <li> Rad im Dienst </li>
            </ul>
        </div>
    </div>
    );
}

export default LeasingCard;