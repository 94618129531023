import "../../Stylesheets/Main.css"

import { PageContext } from "../../App.js"

import { useContext } from "react"
import { useNavigate } from "react-router-dom"


const PageNavDesktop = () => {

    const {page, setPage} = useContext(PageContext)
    const navigate = useNavigate();

    return (
        <nav className = "main-menu">
            <a href="#" className="main-menu-item" onClick={() => {setPage("Start"); navigate("/")}}>
                Start
            </a>
            <a href="#" className="main-menu-item" onClick={() => {setPage("Service"); navigate("/")}}>
                Service
            </a>
            <a href="#" className="main-menu-item" onClick={() => {setPage("Produkte"); navigate("/")}}>
                Produkte
            </a>
            <a href="#" className="main-menu-item" onClick={() => {setPage("Kontakt"); navigate("/")}}>
                Kontakt
            </a>
        </nav>
    );
}

export default PageNavDesktop