import "./../Stylesheets/Main.css"

const ServiceWerkstatt = () => {
    return (
        <div className="service-wrapper-werkstatt background-text">
            <div className="service-column">
                <h2>
                    Werkstatt     
                </h2>
                <p>
                    Gerne helfen wir Ihnen mit unserem hauseigenen Werkstatt und Montageservice.
                    Egal welches Fahrrad, die Reparatur erfolgt in jedem Fall schnell und kompetent.
                    Wir bieten Service für Magura, Rohloff, Shimano und auch für Fremdräder. Wir erstellen Kostenvoranschläge und bauen auf Wunsch auch komplette Räder oder Laufräder auf.
                    Außerdem ist, für unsere Fahrräder, die Erstinspektion völlig kostenlos.
                </p>
            </div>
                
            <div className="service-column">
                <h2>
                    Weitere Services
                </h2>
                <ul>
                    <li> DHL Verkaufspunkt </li>
                    <li> Leihfahrräder </li>
                    <li> Verkauf von gebrauchten Fahrrädern </li>
                    <li> Inzahlungnahme von gebrauchten Fahrrädern </li>
                </ul>
            </div>
            <div className="service-column service-title">
                <h1>
                    Service
                </h1>
            </div>
        </div>
    );
}

export default ServiceWerkstatt;